<template>
<footer id="Contact" class="py-5 text-white bg-primary">
  <div class="container-lg contact-details my-4 my-lg-5">
    <div class="row gx-sm-5 flex-wrap justify-content-center justify-content-lg-between">
      <div class="col-12 col-lg-6 col-lg-4 text-center text-lg-start">
        <h2 style="font-weight: 500">Let's Discuss Your Next Project</h2>
        <div class="mt-4 mb-5 mb-lg-4 d-inline-flex flex-lg-column gap-3 flex-wrap justify-content-center">
          <!-- TODO: update contact details -->
          <a target="_blank" href="mailto:sabelozulu@gmail.com">
            <button type="button" class="btn btn-secondary rounded px-4 py-2">sabelozulu@gmail.com</button>
          </a>
          <a target="_blank" href="tel:+27 73 869 7766">
            <button type="button" class="btn btn-secondary rounded px-4 py-2">+27 73 869 7766</button>
          </a>
        </div>
      </div>
      <div class="col-12 col-sm-auto col-lg-3 text-center text-sm-start">
        <div class="w-auto">
          <div class="mb-4 mb-sm-5">
            <b class="d-block">Service Areas:</b>
            <div class="lh">Durban, South Africa</div>
          </div>
          <div>
            <b class="d-block">Corporate:</b>
            <!-- TODO: add content to these pages or remove this section -->
            <div class="d-inline-flex flex-column lh">
              <router-link target="_blank" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
              <router-link target="_blank" :to="{ name: 'TermsAndConditions' }">Terms & Conditions</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-auto col-lg-3 mt-4 mt-sm-0 text-center text-sm-start">
        <div class="mb-4 ">
          <!-- TODO: update working hours -->
          <b class="d-block">Working Hours:</b>
          <div class="lh">
            <div>Mon - Fri: 8am - 8pm</div>
            <div>Saturday: 9am - 7pm</div>
            <div>Sunday: 9am - 8pm</div>
          </div>
        </div>
      </div>
      <div class="small mt-5 mt-lg-4 text-center text-lg-start">{{ new Date().getFullYear() }} &#169; Sabelo's Electrical & Engineering</div>
    </div>
  </div>
</footer>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.contact-details {
  p {
    text-transform: none;
    font-weight: 400;
  }

  a {
    color: #ffffff;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      &:hover {
        color: $tertiary;
        text-decoration: underline;
      }
    }
  }

  .lh {
    line-height: 1.8;
  }

  .socials {
    img {
      @include media-breakpoint-up(lg) {
        &:hover {
          filter: invert(25%) sepia(40%) saturate(650%)  brightness(105%) contrast(95%);
        }
      }
    }
  }
}
</style>
