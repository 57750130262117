import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/HomeView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicyView.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '@/views/TermsAndConditionsView.vue')
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const hamburgerMenu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])")
    if (hamburgerMenu && hamburgerMenu.offsetWidth > 0 && hamburgerMenu.offsetHeight > 0) {
      hamburgerMenu.click();
    }
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      const navBar = document.querySelector("#nav") as HTMLElement;
      return { el: to.hash, top: navBar?.offsetHeight || 0, behavior: "smooth" };
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

export default router