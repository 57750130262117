<template>
  <Navbar class="shadow-sm" />
  <main>
    <router-view />
  </main>
  <ContactDetails />
</template>

<script setup lang="ts">
  import { useHead } from "@unhead/vue";
  import Navbar from "@/components/navigation/Navbar.vue";
  import ContactDetails from "@/components/ContactDetails.vue";

  import router from "./router";

  useHead({
    titleTemplate: (titleChunk) => {
      return titleFormat(titleChunk);
    },
    // TODO: update meta data
    // meta: [
    //   { name: "description", content: "" },
    //   { name: "keywords", content: "", key: "keywords-tag" },
    //   { name: "robots", content: " ", key: "robots-tag" }
    // ],
  })

  function titleFormat (titleChunk: any) {
    const prependString = router.currentRoute.value.path == "/" ? "" : " | Sabelo's Electrical & Technologies";
    return titleChunk ? titleChunk + prependString : "Sabelo's Electrical & Technologies";
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/bootstrap.scss";
</style>
