import { useGtm } from '@gtm-support/vue-gtm';

export function scrollTop (toScrollSelector: string = '', scrollIfAbove = true) {
  if(toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if(destination) {
      const navBar = document.querySelector("#navbar") as HTMLElement;
      const scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0);
      
      if(scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      window.scrollTo(0, scrollOffset);
    }
    
  } else {
    window.scrollTo(0, 0);
  }
  
  return;
}

export function triggerGtmEvent (event: Object) {
  const gtm = useGtm();
  gtm?.trackEvent(event);
}

export function hasProp (obj: any = null, prop: string) {
  if (obj && prop) {
    const hasProp = Object.prototype.hasOwnProperty.call(obj, prop);

    if (hasProp) {
      return (obj[prop] !== "") ? true : false;
    }
  }
  return false;
}