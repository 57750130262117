<template>
  <nav id="nav" class="navbar navbar-expand-xl bg-white sticky-top py-4">
    <div class="container-fluid px-0 px-md-4 px-lg-5 py-1 mx-2 mx-md-4 mx-lg-5">
      <router-link class="navbar-brand logo-style" :to="{ name: 'Home' }" @click="homeScroll()">
        <img src="/logo.png" class="img-fluid" alt="Sabelo's Electrical & Technologies" />
      </router-link>
      <button class="navbar-toggler" @click="toggleClick()" type="button" data-bs-toggle="collapse" data-bs-target="#NavDropdown" aria-controls="NavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-xl-end" id="NavDropdown">
        <ul class="navbar-nav text-uppercase">
          <li class="nav-item mt-2 mt-xl-0 mx-0 mx-xl-2">
            <router-link class="nav-link text-dark px-3" :to="{ name: 'Home', hash: '#About' }">About</router-link>
          </li>
          <li class="nav-item mx-0 mx-xl-2">
            <router-link class="nav-link text-dark px-3" :to="{ name: 'Home', hash: '#Services' }">Services</router-link>
          </li>
          <li class="nav-item mx-0 mx-xl-2">
            <router-link class="nav-link text-dark px-3" :to="{ name: 'Home', hash: '#Testimonials' }">Testimonials</router-link>
          </li>
          <li class="nav-item mx-0 mx-xl-2">
            <router-link class="nav-link text-dark px-3" :to="{ hash: '#Contact' }">Contact</router-link>
          </li>
					<li class="nav-item mt-2 mt-xl-0 ms-3 ms-xl-2">
						<a target="_blank" href="tel:+27 73 869 7766">
							<button type="button" class="btn btn-primary rounded px-3 py-2">+27 73 869 7766</button>
						</a>
					</li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { scrollTop } from "@/utils/functions";
function homeScroll() {
  if (window.scrollY == 0) {
    return;
  }

  setTimeout(() => {
    scrollTop("");
  }, 50);
}
function toggleClick() {
  const menu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])");
  if (menu) {
    document.querySelector("main")!.addEventListener("click", collapseMenu);
  } else {
    document.querySelector("main")!.removeEventListener("click", collapseMenu);
  }
}
function collapseMenu() {
  const hamburgerMenu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])");
  if (hamburgerMenu && hamburgerMenu.offsetWidth > 0 && hamburgerMenu.offsetHeight > 0) {
    hamburgerMenu.click();
  }
  document.querySelector("main")!.removeEventListener("click", collapseMenu);
}
</script>

<style lang="scss" scoped>
.navbar-nav {
  .nav-link {
    font-weight: 300;
  }
  .btn {
    font-weight: 300;
  }
}
.navbar-brand {
  img {
    max-height: 42px;
  }
  @include media-breakpoint-down(sm) {
    display: inline-block;
    max-width: calc(100% - 85px);
  }
}
</style>
